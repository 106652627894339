import React from 'react';
import history from '../../../services/history';
import './ActivityNavigation.css';
import BackButton from '../../BackButton/BackButton';
import { FaChevronLeft as PrevIcon, FaChevronRight as NextIcon } from 'react-icons/fa';
import { DASHBOARD_URL, ACTIVITY_URL } from '../../../constants/routes';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ActivityNavigation = ({ id, activityIDs }) => {
    const index = activityIDs.indexOf(id);
    const prevID = activityIDs[index - 1] || null;
    const nextID = activityIDs[index + 1] || null;

    const prevButton = (
        <button 
            className="prevActivity" 
            onClick={() => clickHandler(prevID)} 
            style={ !prevID ? { visibility: 'hidden'} : null }
        >
            <span>Previous Activity</span>
            <PrevIcon />
        </button>
    );

    const nextButton = (
        <button 
            className="nextActivity" 
            onClick={() => clickHandler(nextID)} 
            style={ !nextID ? { visibility: 'hidden'} : null }
        >
            <NextIcon />
            <span>Next Activity</span>
        </button>
    );

    const clickHandler = (toID) => {
        history.push( ACTIVITY_URL(toID) );
    };

    return (
        <Row>
            <Col xs={12}>
                <div className="ActivityNavigation">
                    <BackButton route={`${DASHBOARD_URL}#activities`} text="Back to Activities" />
                    <div>
                        {prevButton}
                        {nextButton}
                    </div>
                </div>
            </Col>
        </Row>
        
    );
};

export default ActivityNavigation;