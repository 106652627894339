import React, { useState, useEffect } from 'react';
import './Toggle.scss';

const Toggle = ({ handler, id, checked }) => {
    // @todo - make this styled component;

    // @todo - create unique ID
    const uid = id ? id : 'toggle';

    const [isChecked, setIsChecked] = useState(checked === true ? true : false);

    const toggleHandler = (e) => {
        if (handler) handler(isChecked);
    };

    useEffect(() => {
        toggleHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChecked]);

    return (
        <div className="Toggle">
            <label className="Toggle__switch" htmlFor={uid}>
                <input 
                    type="checkbox" 
                    id={uid} 
                    onChange={(e) => setIsChecked(e.currentTarget.checked)}
                    checked={isChecked}
                />
                <div className="slider round"></div>
            </label>
        </div>
    );
};

export default Toggle;