import React from 'react';
import './RacePredictionsData.css';
import Panel from '../Panel/Panel';
import Table from '../Table/Table';
import { racePrediction } from '../../utils/conversion';

const RacePredictorData = ({ distance, time }) => {
    const columns = [
        { key: 'distance', value: 'Race Distance' },
        { key: 'time', value: 'Predicted Time' },
    ];

    const raceData = [];
    const tenK = { distance: '10k', time: racePrediction(distance, time, 10000) };
    const halfMarathon = { distance: 'Half Marathon', time: racePrediction(distance, time, 21097) };
    const marathon = { distance: 'Marathon', time: racePrediction(distance, time, 42195) };

    if (distance < 10000) raceData.push(tenK);
    if (distance < 21083) raceData.push(halfMarathon);
    if (distance < 42165) raceData.push(marathon);

    // @todo - check what happens if the run was a marthon, i.e. race predictions are empty, and handle that in UI

    return (
        <div className="RacePredictionsData dashBlock">
            <Panel title="Race Predictions">
                <div className="RacePredictionsData__table">
                    <Table columns={columns} data={raceData} />
                </div>
            </Panel>
        </div>
    );
};

export default RacePredictorData;
