import React from 'react';
import Hero from '../../components/Hero/Hero';
import homeBg from '../../assets/images/home-bg-alt.jpg';
import StravaConnectButton from '../../components/StravaConnectButton/StravaConnectButton';

const Home = () => {
    return (
        <div className="Home page">
            <Hero bgImg={ homeBg }>
                <div className="container">
                    <div className="Hero__panel">
                        <h1>The Strava Performance Analysis Platform.</h1>
                        <p>Ready to get started?</p>
                        <StravaConnectButton />
                    </div>
                </div>
            </Hero>
        </div>
    );
};

export default Home;