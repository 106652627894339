import React from 'react';
import './ActivityHead.css';
import Panel from '../../Panel/Panel';
import dayjs from '../../../services/dayjs';
// import mapPlaceholder from '../../../assets/images/map-placeholder.jpg';

const ActivityHead = ({ id, name, start_date, device_name, description }) => {
    const date = dayjs(start_date).format('Do MMMM YYYY');

    return (
        <div className="ActivityHead dashBlock">
            <Panel>
                <div className="ActivityHead__left">
                    <h1>{ name }</h1>
                    <time className="ActivityHead__date">{ date }</time>
                    { device_name && <div className="ActivityHead__device">Recorded on { device_name }</div> }
                    { description &&
                        <blockquote className="ActivityHead__description">{ description }</blockquote> 
                    }
                    <a href={`https://www.strava.com/activities/${id}`} target="_blank" rel="noopener noreferrer nofollow">
                        View on Strava
                    </a>
                </div>
                {/* <div className="ActivityHead__right">
                    <div className="Map">
                        <img src={mapPlaceholder} alt="map placeholder" />
                    </div>
                </div> */}
            </Panel>
        </div>
    );
};

export default ActivityHead;