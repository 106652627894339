import React from 'react';
import { useSelector } from 'react-redux';
import './SplitsData.css';
import Panel from '../Panel/Panel';
import Table from '../Table/Table';
import { formatShortDistance, formatTime } from '../../utils/conversion';
import UnitToggle from '../UnitToggle/UnitToggle';

const SplitsData = ({ splitsMetric, splitsStandard }) => {
    const { unit } = useSelector(state => state.strava);
    const columns = [
        { key: 'split', value: unit === 'metric' ? 'Kilometer' : 'Mile' },
        { key: 'time', value: 'Time' },
        { key: 'elevation', value: 'Elevation' },
        { key: 'paceZone', value: 'Pace Zone' } // todo - what is this? Why is it always 0?
    ];

    // todo - use Memo
    const filterSplitsData = (splits = []) => {
        const newArr = [];

        splits.forEach(s => {
            const {
                split,
                elapsed_time,
                elevation_difference,
                pace_zone
            } = s;

            const newObj = {
                split: split,
                time: formatTime(elapsed_time, unit),
                elevation: formatShortDistance(elevation_difference, unit),
                paceZone: pace_zone
            };
            newArr.push(newObj);
        });
        return newArr;
    };

    const data = (unit === 'metric' ? filterSplitsData(splitsMetric) : filterSplitsData(splitsStandard) );

    return (
        <div className="SplitsData dashBlock">
            <Panel title="Splits" control={ data.length > 0 && <UnitToggle /> }>
                <div className="SplitsData__table">
                    { data.length > 0 && <Table columns={columns} data={data} />}
                    { !data.length && <p>No Splits data available for this activity.</p> }
                </div>
            </Panel>
        </div>
    );
};

export default SplitsData;
