import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import history from '../../services/history';
import Loading from '../Loading/Loading';
import SyncError from '../Error/SyncError';
import { sync } from '../../store/slices/strava';
import { DASHBOARD_URL, SETTINGS_URL } from '../../constants/routes';

export const SyncMessage = () => {
    return (
        <Loading>
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
                <p>Syncing your Strava data.</p>
                <p>This might take a moment - please sit tight!</p>
            </div>
        </Loading>
    );
};

// @todo - finish doing something with this or delete
export const SyncSuccess = () => {
    const backToSettings = () => {
        // @todo - don't want to redirect as we will already be on settings page. 
        // Change status in Settings instead to dismiss this screen.
        history.push(SETTINGS_URL);
    };

    return (
        <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
            <p>Syncing Successful!</p>
            <p className="inlineButtons">
                <button className="btn btn--success" onClick={() => backToSettings()}>OK</button>
            </p>
        </div>
    );
};

const Sync = () => {
    const dispatch = useDispatch();
    const athlete = useSelector(state => state.athlete);
    const activities = useSelector(state => state.activities);
    const { authorized, syncStatus, syncError, lastSync } = useSelector(state => state.strava);
    const { debug } = useSelector(state => state.app);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (syncStatus === 'idle') dispatch(sync());

        if ( syncStatus === ('syncing' || 'pending' || 'refreshing') ) setError(null);
        
        if (syncStatus === 'success' && lastSync && authorized && activities && athlete) {
            history.push(DASHBOARD_URL);
        }

        if (syncStatus === 'failed') {
            let err = debug ? syncError : 'Sync with Strava failed. Please try again later.';
            setError(err); // @todo - error state might be redundant
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [syncStatus]);

    return error ? <SyncError errorMessage={error} /> : <SyncMessage />;
};

export default Sync;
