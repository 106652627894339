import React from 'react';
import './SubFooterMenu.scss';
import InlineList from '../../_styled/InlineList';

const SubFooterMenu = () => {
    return (
        <div className="SubFooterMenu">
            <InlineList>
                <li><small><a href="#" target="_blank">Privacy Policy</a></small></li>
                <li><small><a href="#" target="_blank">Terms of Use</a></small></li>
            </InlineList>
        </div>
    );
};

export default SubFooterMenu;