import React from 'react';
import './ProfileImage.css';
import profileDefault from '../../assets/images/strava/profile-default.png';
import { isValidURL } from '../../utils/validation';

const Profile = ({ imgSrc }) => {
    const img = isValidURL(imgSrc) ? imgSrc : profileDefault;

    return (
        <div className="ProfileImage">
            <img src={img} alt="User profile"/>
        </div>
    );
};

export default Profile;
