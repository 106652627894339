import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './UnitToggle.css';
import { toggleUnit } from '../../store/slices/strava';

const UnitToggle = () => {
    // @todo - refactor this to use component state before dispatching to Redux to prevent lag in UI on toggle
    const dispatch = useDispatch();
    const { unit } = useSelector(state => state.strava);
    const toggleUnitType = (type) => dispatch(toggleUnit(type));
    const units = [
        { type: 'imperial', text: 'Miles' },
        { type: 'metric', text: 'Kilometres' }
    ];
    const buttons = units.map(u => (
        <button 
            value={u.type} 
            onClick={e => toggleUnitType(e.target.value)}
            className={ unit === u.type ? 'active' : null }
            key={u.type}
        >
            { u.text }
        </button>
    ));

    return (
        <div className="UnitToggle">
            { buttons }
        </div>
    );
};

export default UnitToggle;