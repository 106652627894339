import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import history from '../../services/history';
import './Dashboard.css';
import Profile from '../../components/Profile/Profile';
import UserStats from '../../components/UserStats/UserStats';
import RunData from '../../components/RunData/RunData';
import { isEmptyObject } from '../../utils/helper';
import Loading from '../Loading/Loading';
import DashboardLayout from '../../layouts/Dashboard/DashboardLayout';
import DashboardHeader from '../../layouts/Dashboard/DashboardHeader/DashboardHeader';
import FullWidthRow from '../../layouts/FullWidthRow/FullWidthRow';
import { sync } from '../../store/slices/strava';
import Error from '../Error/Error';

const Dashboard = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { hash } = useLocation();
    const dispatch = useDispatch();
    const athlete = useSelector(state => state.athlete.data);
    const activities = useSelector(state => state.activities.data);
    const stats = useSelector(state => state.stats.data);
    const { debug } = useSelector(state => state.app);
    const { lastSync, syncError, syncStatus } = useSelector(state => state.strava);

    // const checkAuthenticated = async () => {
        // Check last sync date in localStorage
        // Athlete - update if localStorage data older than 7 days
        // Stats - update if data older than 1 hour
        // Activities - Get anything from last sync date, plus previous 7 days to account for amendments
        // Will need option to remove outdated data from localstorage (e.g. deleted activities)
        // Option to force full resync of everything up-to-date
        // Don't update/set state in component - set it in context or redux
    // }

    const checkUser = async () => {
        const timestamp = Date.now();
        const cutoff = timestamp - (1000 * 60 * 60 * 24); // 24 hours

        if ( !lastSync || (lastSync < cutoff) ) {
            setIsLoading(true);
            await dispatch(sync());
        }
    };

    // initial render
    useEffect(() => { 
        setError(null);
        // @todo - reset any sync errors
        checkUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (syncStatus === 'failed') {
            let err = debug ? syncError : 'Sync with Strava failed. Please try again later.';
            setError(err);
            setIsLoading(false);
        }

        if (syncStatus === 'success' && lastSync) {
            setError(null);
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [syncStatus, syncError]);

    // scroll to ID
    // @todo - this doesn't work properly!!!
    useEffect(() => {
        if (hash && document.getElementById(hash.substr(1))) {
            document.getElementById(hash.substr(1)).scrollIntoView(true);
            document.getElementById(hash.substr(1)).scrollTop = -2000;
        }
    }, [hash]);

    const userStats = !isEmptyObject(stats) ? <UserStats stats={stats} /> : null;

    if ( isLoading ) return <Loading />;

    return error ? <Error errorMessage={error} /> : (
        <DashboardLayout>
            <DashboardHeader left={<Profile user={athlete} />} right={userStats} />
            <FullWidthRow>
                <RunData activities={activities} />
            </FullWidthRow>
        </DashboardLayout>
    );
}

export default Dashboard;
