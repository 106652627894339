import React from 'react';
import './Copyright.scss';

const Copyright = () => {
    const currentYear = new Date().getFullYear();
    return (
        <small className="Copyright">Copyright &copy; Stranalytics { currentYear }</small>
    );
};

export default Copyright;