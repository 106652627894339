import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import history from '../../services/history';
import { fetchAthlete } from './athlete';
import { fetchStats } from './stats';
import { fetchAllActivities, fetchNewActivities } from './activities';
import { cl } from '../../utils/helper';
import { DEAUTHORIZE_URL } from '../../constants/api';

export const stravaInitialState = {
    authorized: false,
    syncStatus: 'idle',
    lastSync: null,
    syncError: null,
    deleteStatus: 'idle',
    scope: null,
    unit: 'metric' // 'metric' | 'imperial' @todo - set this based on user prefs
};

export const resetState = createAction('resetState');

export const sync = createAsyncThunk('strava/sync',
    async ({ forceSync = false, fullSync = false } = {}, { dispatch, getState }) => {
        const { strava } = getState();
        const { lastSync } = strava;

        const shouldPerformFullSync = () => (!lastSync || fullSync === true) ? true : false;

        (true === shouldPerformFullSync()) 
            ? await dispatch( syncAllData(forceSync) ) 
            : await dispatch( syncNewData(forceSync) );
        
        const { athlete, activities } = getState();

        if (athlete.error) return Promise.reject(athlete.error);
        if (activities.error) return Promise.reject(activities.error);
    }
);

export const syncAllData = createAsyncThunk('strava/syncAllData',
    async (forceSync = false, { dispatch, getState }) => { // @todo - could remove forceSync
        try {
            cl('Syncing all data.');
            // if (forceSync) await dispatch('strava/unsync'); // @todo - is this right or needed?
            await dispatch(fetchAthlete());
            await dispatch(fetchStats());
            await dispatch(fetchAllActivities());

            const { athlete, activities } = getState();

            if (athlete.error) return Promise.reject(athlete.error);
            if (activities.error) return Promise.reject(activities.error);
        } catch (e) {
            cl('Error: syncAllData()', e);
            return Promise.reject(e);
        }
    }
);

export const syncNewData = createAsyncThunk('strava/syncNewData',
    async (forceUpdate = false, { dispatch, getState }) => {
        try {
            cl('Syncing all data.');
            await dispatch(fetchAthlete()); // todo - does fetchAthlete need params?
            await dispatch(fetchStats());
            await dispatch(fetchNewActivities(forceUpdate));

            const { athlete, activities } = getState();
            
            if (athlete.error) return Promise.reject(athlete.error);
            if (activities.error) return Promise.reject(activities.error);
        } catch (e) {
            cl('Error: syncNewData()', e);
            return Promise.reject(e);
        }
    }
);

// export const unsync = createAsyncThunk('strava/unsync', async () => {
//     // todo - This needs to clear the synced localStorage items (stats, activities etc. - possibly athlete?)
//     // and reset/nullify any state relating to lastSync/lastUpdated, so that a full re-sync can occur. Ideally
//     // this can be done without deauthorizing/logging the user out, and just be a temporary way to reset state
//     // and localStorage (and anything serverside if necessary) so that a fresh, full new sync can be performed.
// });

// export const logout = createAsyncThunk('strava/logout', async () => {
//     // todo - this needs to log out/deauthorize the user. To log back in they will need to
//     // reconnect to Strava. We can still keep other data in localStorage/serverside to make
//     // the reconnection/login process more painless.
//     // Possibly not needed - could just use deauthorize instead; difference would be that one only
//     // deauthorizes on the front end, while the other sends a request to the server to deauthorize the app's
//     // access to the Strava API completely.
// });

// todo - This needs to remove all data from localstorage and the state,
// logging the user out and unauthorizing, and sending a request to the server
// to deauthorize (while informing the Strava API about this so they can invalidate tokens)
// and deleting all data from the database, server session, tokens, browser/session/auth cookies, 
// and any server-side caches/memory.
export const deleteAllData = createAsyncThunk('strava/deleteAllData', async (params = null, { dispatch }) => {
    try {
        const response = await axios.delete(`${DEAUTHORIZE_URL}`);
        if (response.data /* @todo - check response for errors */) {
            dispatch(resetState());
            localStorage.clear(); // @todo - DO THIS ELSEWHERE. Check this doesn't save again after state change
            history.push('/connect'); // @todo - check if this is needed or if this happens automatically
            return Promise.resolve();
        } else {
            throw new Error('Data could not be deleted. Please try again in a moment or contact Support.');
        }
    } catch (error) {
        cl('Error: deleteAllData()', error);
        return Promise.reject(error);
    }
});

const stravaSlice = createSlice({
    name: 'strava',
    initialState: stravaInitialState,
    reducers: {
        authorize: state => { state.authorized = true }, // for access to STRAVA
        deauthorize: state => { state.authorized = false }, // for revoked access to STRAVA
        unsync: state => { state.lastSync = null },
        logout: state => { state.authorized = false },
        toggleUnit: (state, action) => { state.unit = action.payload === 'imperial' ? 'imperial' : 'metric' }
    },
    extraReducers: {
        // Sync thunk (main)
        [sync.pending]: (state) => {
            state.syncStatus = 'pending';
            state.syncError = null;
        },
        [sync.fulfilled]: (state) => {
            state.syncStatus = 'success';
            state.lastSync = new Date().getTime();
            state.syncError = null;
            state.authorized = true; // @todo - put this somewhere better
        },
        [sync.rejected]: (state, action) => {
            state.syncStatus = 'failed';
            state.syncError = action.error.message;
        },

        // Sync All Data thunk
        [syncAllData.pending]: (state) => {
            state.syncStatus = 'syncing';
        },
        [syncAllData.fulfilled]: (state) => {
            state.syncStatus = 'success';
        },
        [syncAllData.rejected]: (state, action) => {
            state.syncStatus = 'failed';
            state.syncError = action.error.message;
        },

        // Refresh Data thunk
        [syncNewData.pending]: (state) => {
            state.syncStatus = 'refreshing';
        },
        [syncNewData.fulfilled]: (state) => {
            state.syncStatus = 'success';
        },
        [syncNewData.rejected]: (state, action) => {
            state.syncStatus = 'failed';
            state.syncError = action.error.message;
        },

        // Delete Data thunk
        [deleteAllData.pending]: (state) => {
            state.deleteStatus = 'deleting';
        },
        [deleteAllData.fulfilled]: (state) => {
            state.deleteStatus = 'success';
        },
        [deleteAllData.rejected]: (state) => {
            state.deleteStatus = 'failed';
        }
    }
});

export const { toggleUnit, authorize, deauthorize, unsync, logout } = stravaSlice.actions;
export default stravaSlice.reducer;
