import React from 'react';
import './Table.css';
import TableHead from './TableHead/TableHead';
import TableBody from './TableBody/TableBody';

const Table = ({ data, columns }) => {
    return (
        <div className="Table">
            <div className="tableWrapper">
                <table>
                    <TableHead columns={columns} />
                    <TableBody data={data} columns={columns} />
                </table>
            </div>
        </div>
    );
};

export default Table;