import { combineReducers } from 'redux';
import appReducer from './slices/app';
import athleteReducer from './slices/athlete';
import statsReducer from './slices/stats';
import activitiesReducer from './slices/activities';
import stravaReducer from './slices/strava';
import activitiesTableReducer from './slices/activitiesTable';
import settingsReducer from './slices/settings';

const combinedReducer = combineReducers({
    app: appReducer,
    athlete: athleteReducer,
    stats: statsReducer,
    activities: activitiesReducer,
    activitiesTable: activitiesTableReducer,
    strava: stravaReducer,
    settings: settingsReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'resetState') {
        state = undefined;
    }
    return combinedReducer(state, action);
};

export default rootReducer;
