import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const OneColumnRow = ({ children }) => {
    return (
        <Row>
            <Col xs={12} md={{ span: 12, offset: 0 }}>
                {children}
            </Col>
        </Row>
    );
};

export default OneColumnRow;