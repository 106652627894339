import React from 'react';
import { Link } from 'react-router-dom';

const TableBody = ({ data, columns }) => {
    const columnKeys = columns.map(column => column.key);

    const createCells = (datum) => {
        const cells = [];

        columnKeys.forEach((ck, i) => {
            let cell = <td key={ck}></td>;
            
            for ( let key of Object.keys(datum) ) {
                if (key === ck) {
                    cell = ( columns[i].link && datum.link )
                        ? <td key={ck}><Link to={ datum.link }>{ datum[key] }</Link></td>
                        : <td key={ck}>{ datum[key] }</td>;
                    break;
                }
            }
            cells.push(cell);
        });
        return cells;
    }
    
    const createRow = (datum, index) => {
        const cells = createCells(datum);

        return (
            <tr key={index}>
                { cells.map(cell => cell) }
            </tr>
        );
    }

    return (
        <tbody>
            { data.map((datum, index) => createRow(datum, index)) }
        </tbody>
    );
};

export default TableBody;
