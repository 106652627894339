import React from 'react';
import './Stat.css';

const Stat = ({ title, value }) => {
    return (
        <div className="Stat">
            <div className="Stat__value">{ value }</div>
            <h4>{ title }</h4>
        </div>
    );
};

export default Stat;