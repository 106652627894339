import React from 'react';
import './Select.css';
import { MdExpandMore } from 'react-icons/md';

const Select = ({ options, value, selectHandler }) => {
    return (
        <div className="Select">
            <select 
                className="Select" 
                defaultValue={value}
                onChange={(e => selectHandler(e.target.value))}
            >
                { options.map((o, i) => <option value={o.value} key={i}>{ o.label }</option>) }
            </select>
            <MdExpandMore />
        </div>
    );
};

export default Select;