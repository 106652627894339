import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './RunData.css';
import Panel from '../Panel/Panel';
import SortableTable from '../SortableTable/SortableTable';
import UnitToggle from '../UnitToggle/UnitToggle';
import { formatLongDistance, formatDate, formatTime, formatPace, convertPace } from '../../utils/conversion';
import { ACTIVITY_URL } from '../../constants/routes';
import { activitiesTableActions } from '../../store/slices/activitiesTable';

const RunData = ({ activities }) => {
    const dispatch = useDispatch();
    const { unit } = useSelector(state => state.strava);
    const activitiesTableState = useSelector(state => state.activitiesTable);
    const { setTableState } = activitiesTableActions;
    const [runs, setRuns] = useState([]);
    const [columns, setColumns] = useState([
        { key: 'name', value: 'Name', type: 'string', link: true },
        { key: 'date', value: 'Date', type: 'date' },
        { key: 'distance', value: 'Distance', type: 'number' },
        { key: 'time', value: 'Time', type: 'number' },
        { key: 'pace', value: 'Avg. Pace', type: 'number' }
    ]);

    const defineColumns = useCallback(() => {  
        const initialColumns = [
            { key: 'name', value: 'Name', type: 'string', link: true },
            { key: 'date', value: 'Date', type: 'date', format: (date) => formatDate(date) },
            { key: 'distance', value: 'Distance', type: 'number', format: (distance) => formatLongDistance(distance, unit) },
            { key: 'time', value: 'Time', type: 'number', format: (time) => formatTime(time) },
            { key: 'pace', value: 'Avg. Pace', type: 'number', format: (speed) => formatPace(speed, unit) }
        ];

        switch (unit) {
            case 'imperial':
                let imperialColumns = [
                    { key: 'bestHalfMile', value: '\u00BD Mile', type: 'number', format: (time) => formatPace(time) },
                    { key: 'best1Mile', value: '1 Mile', type: 'number', format: (time) => formatPace(time) },
                    { key: 'best2Mile', value: '2 Mile', type: 'number', format: (time) => formatPace(time) },
                    { key: 'best10Mile', value: '10 Mile', type: 'number', format: (time) => formatPace(time) },
                    { key: 'bestHalfMarathon', value: '\u00BD Marathon', type: 'number', format: (time) => formatPace(time) },
                    { key: 'bestMarathon', value: 'Marathon', type: 'number', format: (time) => formatPace(time) },
                ];
                return initialColumns.concat(imperialColumns);
            case 'metric':
            default:
                let metricColumns = [
                    { key: 'best400m', value: '400m', type: 'number', format: (time) => formatPace(time) },
                    { key: 'best1k', value: '1k', type: 'number', format: (time) => formatPace(time) },
                    { key: 'best5k', value: '5k', type: 'number', format: (time) => formatPace(time) },
                    { key: 'best10k', value: '10k', type: 'number', format: (time) => formatPace(time) },
                    { key: 'best15k', value: '15k', type: 'number', format: (time) => formatPace(time) },
                    { key: 'best20k', value: '20k', type: 'number', format: (time) => formatPace(time) },
                    { key: 'best30k', value: '30k', type: 'number', format: (time) => formatPace(time) },
                    { key: 'best50k', value: '50k', type: 'number', format: (time) => formatPace(time) }
                ];
                return initialColumns.concat(metricColumns);
        }
    }, [unit]);

    const getBestEffort = (bestEfforts, name) => {
        if (!bestEfforts) return null;
        const effort = bestEfforts.find(obj => obj.name === name);
        return effort ? effort.elapsed_time : null;
    }

    const filterActivities = useCallback((activities) => {
        const filtered = [];

        if ( Array.isArray(activities) && activities.length ) {
            activities.forEach(activity => {
                let {
                    id,
                    name,
                    distance,
                    elapsed_time: time,
                    start_date: date,
                    average_speed: pace,
                    best_efforts,
                    manual
                } = activity;

                // skip bad data
                // @todo - check server to see why we get undefined entries
                if (id === undefined || date === undefined) return;

                // skip manual activities
                if (manual === true) return;

                let link = ACTIVITY_URL(id);

                let newActivity = {
                    id: id,
                    link: link,
                    name: name,
                    distance: distance,
                    time: time,
                    date: date,
                    pace: convertPace(pace)
                };

                switch (unit) {
                    case 'imperial':
                        newActivity = {
                            ...newActivity,
                            bestHalfMile: getBestEffort(best_efforts, '1/2 mile'),
                            best1Mile: getBestEffort(best_efforts, '1 mile'),
                            best2Mile: getBestEffort(best_efforts, '2 mile'),
                            best10Mile: getBestEffort(best_efforts, '10 mile'),
                            bestHalfMarathon: getBestEffort(best_efforts, '1/2 marathon'),
                            bestMarathon: getBestEffort(best_efforts, 'marathon')
                        };
                        break;
                    case 'metric':
                    default:
                        newActivity = {
                            ...newActivity,
                            best400m: getBestEffort(best_efforts, '400m'),
                            best1k: getBestEffort(best_efforts, '1k'),
                            best5k: getBestEffort(best_efforts, '5k'),
                            best10k: getBestEffort(best_efforts, '10k'),
                            best15k: getBestEffort(best_efforts, '15k'),
                            best20k: getBestEffort(best_efforts, '20k'),
                            best30k: getBestEffort(best_efforts, '30k'),
                            best50k: getBestEffort(best_efforts, '50k'),
                        };
                        break;
                }
                filtered.push(newActivity);
            });
        }
        return filtered;
    }, [unit]);

    const handleUpdateStore = (payload) => {
        dispatch( setTableState(payload) );
    };

    const cols = useMemo(() => defineColumns(), [defineColumns]);
    const filteredActivities = useMemo(() => filterActivities(activities), [filterActivities, activities]);

    useEffect(() => setColumns(columns => [...cols]), [cols]);
    useEffect(() => setRuns(filteredActivities), [filteredActivities]);

    return (
        <div className="RunData dashBlock">
            <Panel title="Activities" control={ runs.length > 0 && <UnitToggle /> } >
                <div className="RunData__table">
                    { runs.length > 0 && 
                        <SortableTable
                            columns={columns}
                            data={runs}
                            state={activitiesTableState}
                            updateState={handleUpdateStore}
                        />
                    }
                    { !runs.length && <p>No activities to show.</p> }
                </div>
            </Panel>
            <div id="activities"></div>
        </div>
    );
};

export default RunData;
