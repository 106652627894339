import React, { useState } from 'react';
import './Panel.scss';
// import MinimisePanel from './MinimisePanel/MinimisePanel';

const Panel = ({ title, control, children }) => {
    const [open, setOpen] = useState(true);

    // const toggle = () => {
    //     setOpen(!open);
    // };

    const header = (title || control) ? (
        <header>
            { title ? <h3>{title}</h3> : null }
            <div className="Panel__control">
                { control ? control : null }
                {/* <MinimisePanel toggleOpen={toggle} isOpen={open} /> */}
            </div>
        </header>
    ) : null;

    const isOpen = open ? 'open' : 'closed';

    return (
        <div className={`Panel ${isOpen}`}>
            { header }
            <div className="Panel__content">
                { children }
            </div>
        </div>
    );
};

export default Panel;