import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import history from '../../services/history';
// import { logout } from '../../store/slices/strava';
import Panel from '../Panel/Panel';
import './Settings.scss';
import SyncButton from '../SyncButton/SyncButton';

const DataSettings = ({ status, syncHandler, refreshHandler, deleteHandler }) => {
    const { lastSync } = useSelector(state => state.strava);
    const [refreshStatusMessage, setRefreshStatusMessage] = useState(null);
    const [deleteStatusMessage, setDeleteStatusMessage] = useState(null);

    const refreshDataHandler = () => {
        if (refreshHandler) refreshHandler();
    };

    const resyncDataHandler = () => {
        if (syncHandler) syncHandler();
    };

    // @todo - delete all cached data, serverside data, deauthorize app from Strava API, and log out
    const deleteDataHandler = () => {
        if (deleteDataHandler) deleteHandler();
    };

    useEffect(() => {
        switch(status) {
            case 'refreshing':
                setRefreshStatusMessage('Refreshing data...');
                break;
            case 'refreshFailed':
                setRefreshStatusMessage('Sync failed. Please try again later.');
                break;
            case 'refreshSuccess':
                setRefreshStatusMessage('Sync successful!');
                break;
            case 'deleting':
                setDeleteStatusMessage('Deleting data before logging out...');
                break;
            case 'deleteSuccess':
                setDeleteStatusMessage('Data deleted.');
                break;
            case 'deleteFailed':
                setDeleteStatusMessage('Delete failed. This is most likely a temporary problem with our server. Please try again later, log out without deleting your data, or contact us for help.');
                break;
            default:
                setRefreshStatusMessage(null);
                break;
        }
    }, [status]);

    const lastUpdated = new Date(lastSync).toLocaleString();

    return (
        <div className="DataSettings SettingsGroup">
            <Panel>
                <h2>Data Settings</h2>

                { /** Refresh **/}
                <div className="Setting">
                    <h4>Refresh Strava Data</h4>
                    <p>Refresh your latest Strava data to retrieve any new activities or stats that may not be showing here yet.</p>
                    <SyncButton 
                        className="btn btn--success" 
                        onClick={() => refreshDataHandler()}
                        syncing={status === 'refreshing' ? true : false}
                    >
                        <span>Refresh Data</span>
                    </SyncButton>
                    { refreshStatusMessage && <p className="status">{ refreshStatusMessage }</p> }
                    <p className="bold italic">Last updated on { lastUpdated }.</p>
                </div>

                { /** Full Sync **/}
                <div className="Setting">
                    <h4>Full Resync With Strava</h4>
                    <p>Perform a full resync of all data with Strava. This may take a little while.</p>
                    <button className="btn" onClick={() => resyncDataHandler()}>Sync Data</button>
                </div>

                { /** Delete **/}
                <div className="Setting">
                    <h4>Delete Data</h4>
                    <p>Remove all of your Strava data from our site, deauthorize this app from your Strava account, and log out.</p>
                    <p>You can always reconnect your Strava account again and resync your data if you change your mind later.</p>
                    <SyncButton
                        className="btn btn--danger"
                        onClick={() => deleteDataHandler()}
                        syncing={status === 'deleting' ? true : false}
                    >
                        <span>Delete Data</span>
                    </SyncButton>
                    { deleteStatusMessage && <p className="status">{ deleteStatusMessage }</p> }
                </div>

            </Panel>
        </div>
    );
};

export default DataSettings;