import React from 'react';
import { useSelector } from 'react-redux';
import './SegmentEffortsData.css';
import Panel from '../Panel/Panel';
import Table from '../Table/Table';
import { formatLongDistance, formatTime } from '../../utils/conversion';
import UnitToggle from '../UnitToggle/UnitToggle';

const SegmentEffortsData = ({ data }) => {
    const { unit } = useSelector(state => state.strava);
    const columns = [
        { key: 'name', value: 'Name' },
        { key: 'time', value: 'Time' },
        { key: 'distance', value: 'Distance' },
        { key: 'rank', value: 'Rank' },
    ];

    // todo - use Memo
    const filterSegmentData = (segments = []) => {
        const newArr = [];

        segments.forEach(s => {
            const {
                name,
                elapsed_time,
                distance,
                pr_rank
            } = s;

            const newObj = {
                name: name,
                time: formatTime(elapsed_time),
                distance: formatLongDistance(distance, unit),
                rank: pr_rank
            };
            newArr.push(newObj);
        });
        return newArr;
    };

    const filteredSegments = filterSegmentData(data);

    return (
        <div className="SegmentEffortsData dashBlock">
            <Panel title="Segment Efforts" control={ filteredSegments.length > 0 && <UnitToggle /> }>
                <div className="SegmentEffortsData__table">
                    { filteredSegments.length > 0 &&
                        <Table columns={columns} data={filteredSegments} />
                    }
                    { !filteredSegments.length && 
                        <p>No Segments data available for this activity.</p>
                    }
                </div>
            </Panel>
        </div>
    );
};

export default SegmentEffortsData;