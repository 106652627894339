import { untrailingSlashIt } from '../utils/helper';

export const API_URL = untrailingSlashIt( process.env.REACT_APP_API_ROOT || 'http://localhost:5000/api' );
export const CLIENT_URL = untrailingSlashIt( process.env.REACT_APP_CLIENT_ROOT || 'http://localhost:3000' );
export const AUTH_URL = `${API_URL}/auth/strava`;
export const ATHLETE_URL = `${API_URL}/athlete`;
export const STATS_URL = `${API_URL}/athlete/stats`;
export const ACTIVITIES_URL = `${API_URL}/athlete/activities/detailed`;
export const DEAUTHORIZE_URL = `${API_URL}/auth/strava/deauthorize`;

export const MAX_ACTIVITIES_LIMIT = 50;
