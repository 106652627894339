import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { ACTIVITIES_URL, MAX_ACTIVITIES_LIMIT } from '../../constants/api';
import { cl } from '../../utils/helper';

export const activitiesInitialState = {
    status: 'idle', // 'idle' | 'loading' | 'success' | 'fail'
    lastUpdated: null, // timestamp (int) | null
    data: null, // Array | null
    error: null // string | null
};

const shouldUpdateActivities = (state) => {
    const activities = state.activities.data;
    const lastUpdated = state.activities.lastUpdated; // make sure this is an integer timestamp
    const currentTimestamp = new Date().getTime();
    const dateOffset = (24 * 60 * 60 * 1000) * 5; // 1 days ( @todo - could make this into a helper function )

    if (!activities && !lastUpdated) return true; 
    if ( lastUpdated < (currentTimestamp - dateOffset) ) return true;
    return false;
};

export const fetchNewActivities = createAsyncThunk('activities/fetchNewActivities', 
    async (forceUpdate = false, { getState }) => {
        // if...
        // 1. a timestamp was provided...
        // 2. it was recent enough that we don't need to update activities...
        // 3. we haven't signalled to force an update regardless...
        // ...then resolve without fetching
        if ( (!shouldUpdateActivities( getState() )) && (!forceUpdate) ) {
            return Promise.resolve();
        }
        try {
            const { activities } = getState();
            const response = await axios.get(`${ACTIVITIES_URL}`, {
                params: { after: ~~(activities.lastUpdated / 1000) },
                timeout: 300000
            });
            return response.data;
            // todo - could check for response.status (200) or response.statusText ('OK') here
        } catch (error) {
            cl(error);
            if (error.response) {
                cl(error.response);
                // todo - do something with these to handle error in UI
            }
            return Promise.reject(error);
        }
    }
);

export const fetchAllActivities = createAsyncThunk('activities/fetchAllActivities',
    async (params = {}, { getState }) => { // todo - getState not used
        try {
            const response = await axios.get(`${ACTIVITIES_URL}`, {
                timeout: 300000
            });
            return response.data;
            // todo - could check for response.status (200) or response.statusText ('OK') here
        } catch (error) {
            cl(error);
            if (error.response) {
                cl(error.response);
                // todo - do something with these to handle error in UI
                // if error response = 429, too many requests - retry button 
                // todo - write proper error handler for various status codes
            }
            return Promise.reject(error);
        }
    }
);

const activitiesSlice = createSlice({
    name: 'activities',
    initialState: activitiesInitialState,
    reducers: {},
    extraReducers: {
        [fetchNewActivities.pending]: state => {
            state.status = 'loading';
            state.error = null;
        },
        [fetchNewActivities.fulfilled]: (state, action) => {
            const LIMIT = process.env.REACT_APP_STRAVA_ACTIVITY_LIMIT || MAX_ACTIVITIES_LIMIT || 50;
            const newActivities = action.payload || [];
            const oldActivities = state.data || [];
            const combined = [...oldActivities, ...newActivities];
            const truncated = combined.slice(Math.max(combined.length - LIMIT, 0)); // limit it to x items

            state.status = 'success';
            state.data = truncated;
            state.lastUpdated = new Date().getTime();
            state.error = null;
        },
        [fetchNewActivities.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        [fetchAllActivities.pending]: state => {
            state.status = 'loading';
            state.error = null;
        },
        [fetchAllActivities.fulfilled]: (state, action) => {
            state.status = 'success';
            state.data = action.payload; // todo - truncate to 50
            state.lastUpdated = new Date().getTime();
            state.error = null;
        },
        [fetchAllActivities.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        }
    }
});

export default activitiesSlice.reducer;
