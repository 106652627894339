import { createSlice } from '@reduxjs/toolkit';

export const settingsInitialState = {
    darkMode: false
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState: settingsInitialState,
    reducers: {
        toggleDarkMode: (state, action) => { state.darkMode = action.payload === true ? true : false }
    }
});

export const { toggleDarkMode } = settingsSlice.actions;
export default settingsSlice.reducer;
