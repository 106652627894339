import strftime from 'strftime';
import dayjs from '../services/dayjs';
import store from '../store/store';

/**
 * Converts a number of seconds into a formated string of 
 * hours, minutes and seconds (1h 23m 45s).
 * 
 * @param {number} secs 
 * @returns {string}
 */
export const formatTime = (secs) => {
    secs = Number(secs);

    if (secs === 0 || null) return '';

    let h = Math.floor(secs / 3600);
    let m = Math.floor(secs % 3600 / 60);
    let s = Math.floor(secs % 3600 % 60);

    return (secs >= 3600) ? `${h}h ${m}m ${s}s` : `${m}m ${s}s`;
};

/**
 * Converts a number of seconds into a formated string of
 * hours, minutes and seconds (hh:mm:ss).
 *
 * @param {number} secs
 * @returns {string}
 */
export const formatPace = (secs) => {
    secs = Number(secs);

    if (secs === 0 || null) return '';

    let h = Math.floor(secs / 3600);
    let m = Math.floor(secs % 3600 / 60);
    let s = Math.floor(secs % 3600 % 60);

    h = twoDigitString(h);
    m = twoDigitString(m);
    s = twoDigitString(s);

    return stripLeadingZero((secs >= 3600) ? `${h}:${m}:${s}` : `${m}:${s}`);
};

/**
 * Converts a date into a locally or preferred formatted date
 *
 * @param {Date|number} date
 * @param {string} format
 * @returns {string}
 */
export const formatDateAlt = (date = null, format = store.athlete.date_preference || '%d/%m/%Y') => {
    format = '%d/%m/%Y'; // todo - temporary
    /**
     * @todo - write function to check locale based on browser language preference, 
     * and select an appropriate locale instead for the format.
     **/
    date = date ? new Date(date) : new Date();
    return strftime(format, date);
};

/**
 * Converts a date into a locally or preferred formatted date
 *
 * @param {Date|number} date
 * @param {string} format
 * @returns {string}
 */
export const formatDate = (date = null, format = 'Do MMM YYYY') => {
    return dayjs(date).format(format)
};

/**
 * Converts a number of metres into a formated number of miles or km.
 * 
 * @param {number} distance
 * @param {number} unit 'imperial' | 'metric'
 * @returns {number}
 */
export const convertLongDistance = (distance, unit) => {
    return ((unit === 'imperial') ? (distance / 1609.344) : (distance / 1000)).toFixed(2);
};

/**
 * Returns 'mi' for unit 'imperial' (miles) and 'km' for unit 'metric' (kilometres).
 * 
 * @param {number} unit 'imperial' | 'metric'
 * @returns {string}
 */
export const distanceLongUnit = (unit) => unit === 'imperial' ? 'mi' : 'km';

/**
 * Returns 'ft' for unit 'imperial' (feet) and 'm' for unit 'metric' (kmetres).
 * 
 * @param {number} unit 'imperial' | 'metric'
 * @returns {string}
 */
export const distanceShortUnit = (unit) => unit === 'imperial' ? 'ft' : 'm';

/**
 * Uses convertDistance() and distanceShort() to return a combined, 
 * formatted string.
 * 
 * @param {number} distance
 * @param {number} unit 'imperial' | 'metric'
 * @returns {string}
 */
export const formatLongDistance = (distance, unit) => convertLongDistance(distance, unit) + ' ' + distanceLongUnit(unit);

/**
 * Uses convertDistance() and distanceShort() to return a combined, 
 * formatted string.
 * 
 * @param {number} distance
 * @param {number} unit 'imperial' | 'metric'
 * @returns {string}
 */
export const formatShortDistance = (distance, unit) => distance + ' ' + distanceShortUnit(unit);

/**
 * Converts metres per second into a number of seconds per km or miles.
 * 
 * @param {number} mps
 * @param {number} unit 'imperial' | 'metric'
 * @returns {string}
 */
export const convertPace = (mps, unit) => {
    const minsPerUnit = (unit === 'imperial') ? (26.8224 / mps) : (16.666666667 / mps); // decimal e.g. 5.632 minutes / km
    const secsPerUnit = minsPerUnit * 60;
    return secsPerUnit;
};

/**
 * Converts metres per second into a formatted string for pace (minutes per mile or minutes per km).
 * 
 * @param {number} mps
 * @param {number} unit 'imperial' | 'metric'
 * @returns {string}
 */
export const convertAndFormatPace = (mps, unit) => {
    const minsPerUnit = (unit === 'imperial') ? (26.8224 / mps) : (16.666666667 / mps); // decimal e.g. 5.632 minutes / km
    const secsPerUnit = minsPerUnit * 60;
    return formatPace(secsPerUnit);
};

/**
 * Converts a number into a two digit number, and returns the value as a string.
 * 
 * @param {number} number 
 * @return {string}
 */
export const twoDigitString = (number) => {
    return number.toLocaleString('en-GB', { minimumIntegerDigits: 2, useGrouping:false } );
};

/**
 * Strips leading zero from a string (if applicable) for use with time formatting.
 * 
 * @param {string} string 
 */
export const stripLeadingZero = (string) => {
    return string.replace(/^[0|\D]*/g, '');
};

/**
 * Algorithm for predicted race times.
 *
 * @param {number} distance
 * @param {number} time
 * @param {number} raceDistance
 * @return {string}
 */
export const racePrediction = (distance, time, raceDistance) => {        
    const predictedTime = ((time * (raceDistance / distance)) * 1.06); // Where does 1.06 come from? Should this be a variable?
    return formatTime(predictedTime);
};
