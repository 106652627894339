export const objectValuesToLowerCaseArray = (obj) => {
    const values = Object.values(obj).map(v => {
        v = v ? v : '';
        return v.toString().toLowerCase();
    });
    return values;
};

export const isEmptyObject = (obj) => Object.keys(obj).length === 0;

export const findResultsInArray = (string, array) => {
    const filteredArray = array.filter(obj => {
        const objectValues = objectValuesToLowerCaseArray(obj);
        const lowerCaseString = string.toString().toLowerCase();

        if ( lowerCaseString.length > 0 ) {
            // loop through objectValues array, and search for substring on each value
            for (let i = 0; i < objectValues.length; i++) {
                if ( objectValues[i].includes(lowerCaseString) ) return true;
            }
        }
        return false;
    });

    return filteredArray;
};

export const untrailingSlashIt = (str) => {
    return str.replace(/\/$/, '');
};

export const trailingSlashIt = (str) => {
    return untrailingSlashIt(str) + '/';
};

export const getSafe = (fn, defaultVal) => {
    try {
        return fn();
    } catch (e) {
        return defaultVal;
    }
};

export const cl = (...args) => {
    if (process.env === 'development') {
        console.log(...args);
    }
};
