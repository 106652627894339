import React from 'react';
import history from '../../services/history';
import './Error.css';
import CenterLayout from '../../layouts/CenterLayout/CenterLayout';

const Error = ({ errorMessage, errorTitle, children }) => {
    const title = <h1>{ errorTitle || 'Error' }</h1>;
    const message = <p className="lead">{ errorMessage || 'Something went wrong.' }</p>;

    const redirect = () => {
        history.push('/');
    };

    return (
        <CenterLayout>
            { title }
            { message }
            { children }
            { !children && 
                <p>
                    <button className="btn btn--primary" onClick={() => redirect()}>
                        Go Back
                    </button>
                </p> 
            }
        </CenterLayout>
    );
};

export default Error;
