import React from 'react';
import './SortIcon.scss';
import { MdArrowUpward as ArrowIcon } from 'react-icons/md';

const SortIcon = ({ direction, colKey, sortKey }) => {
    const createClasses = () => {
        let classes = ['SortIcon'];
        if (direction) classes.push(`SortIcon--${direction}`);
        if (colKey !== sortKey) classes.push('SortIcon--hidden');
        return classes.join(' ');
    };

    const classes = createClasses();

    return (
        <ArrowIcon className={classes} /> 
    );
};

export default SortIcon;