import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import './SortableTable.css';
import SortableTableHead from './SortableTableHead/SortableTableHead';
import SortableTableBody from './SortableTableBody/SortableTableBody';
import TableSearch from './TableSearch/TableSearch';
import Pagination from '../Pagination/Pagination';
import Select from '../Select/Select';
import { findResultsInArray } from '../../utils/helper';

/**
 * Sortable Table refactored to use Redux instead of component state.
 */
const SortableTable = ({ data, columns, state, updateState }) => {
    const {
        sortBy = columns[0].key,
        sortOrder = 'ascending',
        rowsPerPage: rows = 10,
        currentPage: page = 1,
        searchFilter: filter = ''
    } = state;

    const updateStateHandler = (state) => {
        if (updateState) updateState(state);
    };

    const [sortConfig, setSortConfig] = useState({ key: sortBy, direction: sortOrder });
    const [currentPage, setCurrentPage] = useState(page);
    const [rowsPerPage, setRowsPerPage] = useState(rows);
    const [searchFilter, setSearchFilter] = useState(filter);

    // Define refs for state values to update and persist later
    const filterRef = useRef(searchFilter);
    const sortByRef = useRef(sortConfig.key);
    const sortOrderRef = useRef(sortConfig.direction);
    const rowsRef = useRef(rowsPerPage);
    const pageRef = useRef(currentPage);

    // Update the refs when the state changes
    useEffect(() => { filterRef.current = searchFilter }, [searchFilter]);
    useEffect(() => { sortByRef.current = sortConfig.key }, [sortConfig]);
    useEffect(() => { sortOrderRef.current = sortConfig.direction }, [sortConfig]);
    useEffect(() => { rowsRef.current = rowsPerPage }, [rowsPerPage]);
    useEffect(() => { pageRef.current = currentPage }, [currentPage]);

    // Save table state to redux store when navigating away. This only gets called on component unmount.
    useEffect(() => () => {
        updateStateHandler({ 
            searchFilter: filterRef.current, 
            sortBy: sortByRef.current, 
            sortOrder: sortOrderRef.current,  
            rowsPerPage: rowsRef.current,
            currentPage: pageRef.current
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // filter the array to contain only searchable columns for the search filter input to work
    const convertRawValuesToFormatted = (arr, ) => {
        // @todo - use the converted values (e.g. formatted time) to search instead of raw values
    };

    const tableData = useMemo(() => {
        // @todo - use the converted values (e.g. formatted time) to search instead of raw values
        const filteredData = searchFilter ? findResultsInArray(searchFilter, [...data]) : [...data];
        const selectedColumn = columns.filter(col => col.key === sortConfig.key)[0];

        if (selectedColumn) {
            const type = selectedColumn.type;
            const { key, direction } = sortConfig;
            const isAscending = direction === ('ascending' || 'asc');
            const isDescending = direction === ('descending' || 'desc');

            // @todo - move this elsewhere
            // @todo - improve sorting algorithm
            // @todo - sort() sorts in place, so can just return filteredData - don't need sortedData
            let sortedData = filteredData.sort((a, b) => {
                switch (type) {
                    case 'string': // @todo - why isn't this sorting working?
                    case 'number':
                    default:
                        if (a[key] < b[key])
                            return isAscending ? -1 : 1;
                        if (a[key] > b[key])
                            return isAscending ? 1 : -1;
                        return 0;

                    case 'date':
                        if (isAscending)
                            return new Date(a[key]) - new Date(b[key]);
                        if (isDescending)
                            return new Date(b[key]) - new Date(a[key]);
                        return 0;
                };
            });
            return sortedData.filter(row => row[key] !== ('' || null));
        } else {
            return filteredData;
        }
    }, [data, columns, sortConfig, searchFilter]);

    const sortClickHandler = useCallback((key) => {
        let direction = sortConfig.direction;

        if (sortConfig.key === key)
            direction = (sortConfig.direction === ('ascending' || 'asc'))
                ? 'descending'
                : 'ascending';

        setSortConfig({ key, direction });
    }, [sortConfig]);

    const updateCurrentPage = pageNumber => {
        // Force string value from button to int
        const n = parseInt(pageNumber, 10);
        setCurrentPage(n);
    };

    const searchHandler = value => setSearchFilter(value);

    const selectRowsPerPage = (value) => {
        setCurrentPage(1); // todo - might be able to do something clever here to retain position
        setRowsPerPage(value);
    };

    const pagesCount = Math.ceil(tableData.length / rowsPerPage);
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);
    const minEntries = indexOfFirstRow + 1;
    const maxEntries = Math.min(indexOfLastRow, tableData.length);

    const entries = (0 === tableData.length)
        ? <span>No results found.</span>
        : <span>Showing <strong>{minEntries}</strong> to <strong>{maxEntries}</strong> of <strong>{tableData.length}</strong> entries.</span>
        ;

    // @todo - finalize options
    const selectOptions = [
        { value: 10, label: '10' },
        { value: 25, label: '25' },
        { value: 50, label: '50' },
        { value: 100, label: '100' }
    ];

    return (
        <div className="SortableTable">
            <div className="SortableTable__controls">
                <div>
                    Show <Select
                        options={selectOptions}
                        value={rowsPerPage}
                        selectHandler={selectRowsPerPage}
                    /> entries
                </div>
                {/* <TableSearch searchHandler={searchHandler} searchQuery={searchFilter} label="Search" /> */}
            </div>
            <div className="tableWrapper">
                <table>
                    <SortableTableHead
                        columns={columns}
                        sortKey={sortConfig.key}
                        direction={sortConfig.direction}
                        sortClickHandler={sortClickHandler}
                    />
                    <SortableTableBody data={currentRows} columns={columns} />
                </table>
            </div>
            <footer className="SortableTable__footer">
                <p className="SortableTable__entries">
                    {entries}
                </p>
                <Pagination
                    pagesCount={pagesCount}
                    currentPage={currentPage}
                    updateCurrentPage={updateCurrentPage}
                />
            </footer>
        </div>
    );
};

export default SortableTable;
