import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import history from '../../../services/history';
import './SortableTableBody.css';

const SortableTableBody = ({ data, columns }) => {
    const createCells = (datum) => {
        const cells = [];

        columns.forEach((col, i) => {
            let cell = <td key={col.key}></td>;
            
            for ( let key of Object.keys(datum) ) {
                if (key === col.key) {
                    let val = col.format ? col.format( datum[key] ) : datum[key];
                    cell = ( columns[i].link && datum.link )
                        ? <td key={col.key}><Link to={ datum.link }>{ val }</Link></td>
                        : <td key={col.key}>{ val }</td>;
                    break;
                }
            }
            cells.push(cell);
        });
        return cells;
    }
    
    const createRow = (datum, index) => {
        const cells = createCells(datum);

        return (
            <tr key={index} onClick={() => history.push(datum.link)}>
                { cells.map(cell => cell) }
            </tr>
        );
    }

    const rows = useMemo(() => {
        return data.map((datum, index) => createRow(datum, index))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <tbody>
            { rows }
        </tbody>
    );
};

export default SortableTableBody;
