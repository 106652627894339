import axios from 'axios';
// import store from '../store/store';
// import { deauthorize } from '../store/slices/strava';

const instance = axios.create({
    withCredentials: true,
    timeout: 10000
});

// instance.interceptors.response.use(null, error => {
//     if (error.status) {
//         switch (error.status) {
//             case 401:
//                 // [Unauthorized] - Wrong or no authentication ID/password provided
//                 store.dispatch(deauthorize());
//                 break;
//         }
//     }
// });

export default instance;
