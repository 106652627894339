import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Connect from './views/Connect/Connect';
import Dashboard from './views/Dashboard/Dashboard';
import Activity from './views/Activity/Activity';
import Settings from './views/Settings/Settings';
import Sync from './views/Sync/Sync';
import NotFound from './views/NotFound/NotFound';
import { ROOT_URL, DASHBOARD_URL, SETTINGS_URL, CONNECT_URL, SYNC_URL } from './constants/routes';

const ProtectedRoute = ({ auth, ...props }) => {
    return auth ? <Route {...props} /> : <Redirect to={CONNECT_URL} />;
};

const Routes = () => {
    const { authorized: auth } = useSelector(state => state.strava);

    return (
        <Switch>
            <Route exact path={ROOT_URL}>{auth ? <Redirect to={DASHBOARD_URL} /> : <Redirect to={CONNECT_URL} />}</Route>
            <Route exact path={SYNC_URL}>{ auth ? <Redirect to={DASHBOARD_URL} /> : <Sync /> }</Route>
            <Route exact path={CONNECT_URL}>{ auth ? <Redirect to={DASHBOARD_URL} /> : <Connect /> }</Route>
            <ProtectedRoute exact path={DASHBOARD_URL} component={Dashboard} auth={auth}/>
            <ProtectedRoute exact path={`${DASHBOARD_URL}/activity/:id`} component={Activity} auth={auth}/>
            <ProtectedRoute exact path={SETTINGS_URL} component={Settings} auth={auth}/>
            <Route path="*" component={NotFound} />
        </Switch>
    );
};

export default Routes;
