import React, { useState, useEffect, useMemo } from 'react';
import './Pagination.css';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

const Pagination = ({ pagesCount, currentPage, updateCurrentPage }) => {
    const [pageNumbers, setPageNumbers] = useState([]);

    useEffect(() => {
        let n = [];
        for (let i = 1; i <= pagesCount; i++) {
            n.push(i);
        }
        setPageNumbers(n);
    }, [pagesCount]);

    const disabledClass = ( pagesCount <= 1 ) ? 'disabled' : '';

    const prevClass = ( 1 === currentPage || 1 === pagesCount ) 
        ? 'disabled' 
        : '';

    const nextClass = ( pageNumbers.length === currentPage || 1 === pagesCount ) 
        ? 'disabled' 
        : ''; 

    // @todo - use elipses for large numbers of pages and set a max number of buttons
    // @todo - when doing the above, make 'first page' and 'last page' buttons to go before/after the prev/next buttons

    // @todo = useCallback?
    const truncatePageNumbers = () => {
        if (pageNumbers.length <= 8) {
            return (
                pageNumbers.map((p, i) => (
                    <li className={i + 1 === currentPage ? 'active' : null} key={i}>
                        <button onClick={(e) => updateCurrentPage(e.target.value, e)} value={i + 1}>{i + 1}</button>
                    </li>
                ))
            );
        }

        const first = 1;
        const last = pageNumbers.length;
        let numbers = [];

        if (currentPage >= last - 2) {
            numbers = [last - 2, last - 1, last];

            return (
                <>
                    <li className={first === currentPage ? 'active' : null} key={first}>
                        <button onClick={(e) => updateCurrentPage(e.target.value, e)} value={first}>{first}</button>
                    </li>
                    <li>...</li>
                    {numbers.map((p) => (
                        <li className={p === currentPage ? 'active' : null} key={p}>
                            <button onClick={(e) => updateCurrentPage(e.target.value, e)} value={p}>{p}</button>
                        </li>
                    ))}
                </>
            );
        }

        if (currentPage > 3) {
            numbers = [currentPage - 1, currentPage, currentPage + 1];

            return (
                <>
                    <li className={first === currentPage ? 'active' : null} key={first}>
                        <button onClick={(e) => updateCurrentPage(e.target.value, e)} value={first}>{first}</button>
                    </li>
                    <li>...</li>
                    { numbers.map((p) => (
                        <li className={p === currentPage ? 'active' : null} key={p}>
                            <button onClick={(e) => updateCurrentPage(e.target.value, e)} value={p}>{p}</button>
                        </li>
                    )) }
                    <li>...</li>
                    <li className={last === currentPage ? 'active' : null} key={last}>
                        <button onClick={(e) => updateCurrentPage(e.target.value, e)} value={last}>{last}</button>
                    </li>
                </>
            );
        }

        if (currentPage <= 3) {
            numbers = [1, 2, 3];

            return (
                <>
                    { numbers.map((p) => (
                        <li className={p === currentPage ? 'active' : null} key={p}>
                            <button onClick={(e) => updateCurrentPage(e.target.value, e)} value={p}>{p}</button>
                        </li>
                    )) }
                    <li>...</li>
                    <li className={last === currentPage ? 'active' : null} key={last}>
                        <button onClick={(e) => updateCurrentPage(e.target.value, e)} value={last}>{last}</button>
                    </li>
                </>
            );
        }
    };

    const truncatedPageNumbers = useMemo(() => {
        return truncatePageNumbers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [pageNumbers, currentPage]);

    return (
        <ul className={`Pagination ${disabledClass}`}>
            <li className={prevClass}>
                <button onClick={(e) => updateCurrentPage(currentPage - 1, e)} disabled={prevClass}>
                    <MdChevronLeft />
                </button>
            </li>
            { truncatedPageNumbers }
            <li className={nextClass}>
                <button onClick={(e) => updateCurrentPage(currentPage + 1, e)} disabled={nextClass}>
                    <MdChevronRight />
                </button>
            </li>
        </ul>
    );
};

export default Pagination;
