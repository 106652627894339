import React from 'react';
import './Hero.css';

const Hero = (props) => {
    return (
        <div className="Hero" style={ { backgroundImage: `url(${props.bgImg})` }  }>
            { props.children }
        </div>
    );
};

export default Hero;