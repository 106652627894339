import React from 'react';
import './CenterLayout.scss';

const CenterLayout = ({ children }) => {
    return (
        <div className="CenterLayout">
            { children }
        </div>
    );
};

export default CenterLayout;