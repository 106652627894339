import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ActivityHead from '../../components/Activity/ActivityHead/ActivityHead';
import ActivityNavigation from '../../components/Activity/ActivityNavigation/ActivityNavigation';
import ActivityStats from '../../components/Activity/ActivityStats/ActivityStats';
import BestEffortsData from '../../components/BestEffortsData/BestEffortsData';
import SplitsData from '../../components/SplitsData/SplitsData';
import SegmentEffortsData from '../../components/SegmentEffortsData/SegmentEffortsData';
import RacePredictionsData from '../../components/RacePredictionsData/RacePredictionsData';
import DashboardLayout from '../../layouts/Dashboard/DashboardLayout';
import ActivityHeader from '../../layouts/Activity/ActivityHeader';
import TwoColumnRow from '../../layouts/TwoColumnRow/TwoColumnRow';
import TwoColumnRowReverse from '../../layouts/TwoColumnRow/TwoColumnRowReverse';
import NotFound from '../NotFound/NotFound';

const Activity = ({ match }) => {
    const activities = useSelector(state => state.activities.data);
    const matchID = parseInt(match.params.id, 10); // Convert from string to int
    // @todo - get current filtered subset of activities (once stored in state) or all
    const activity = activities.find(a => a.id === matchID);
    // ids of currently filtered activites (or all)
    const activityIDs = activities.map(a => a.id);

    useEffect(() => {
        // fetch activity with ID
    }, []);

    if (!activity) return <NotFound />

    const {
        id,
        average_speed,
        best_efforts,
        calories,
        description,
        device_name,
        distance,
        elapsed_time,
        elev_high,
        elev_low,
        kudos_count,
        name,
        segment_efforts,
        start_date,
        map,
        max_speed,
        moving_time,
        splits_metric = [],
        splits_standard = [],
        total_elevation_gain
    } = activity;

    return (
        <DashboardLayout>
            <div className="Activity">
                <ActivityHeader 
                    left={<ActivityHead {...activity} />}
                    right={<ActivityStats {...activity} />}
                >
                    <ActivityNavigation id={id} activityIDs={activityIDs} />
                </ActivityHeader>
                <TwoColumnRow 
                    left={<SplitsData splitsMetric={splits_metric} splitsStandard={splits_standard} />}
                    right={<BestEffortsData data={best_efforts} />}
                />
                <TwoColumnRowReverse
                    left={<RacePredictionsData distance={distance} time={elapsed_time} />}
                    right={<SegmentEffortsData data={segment_efforts} />} 
                />
            </div>
        </DashboardLayout>
    );
};

export default Activity;