import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Panel from '../Panel/Panel';
import Toggle from '../Toggle/Toggle';
import './Settings.scss';
import { toggleDarkMode } from '../../store/slices/settings';

const DisplaySettings = () => {
    const dispatch = useDispatch();
    const { darkMode } = useSelector(state => state.settings);

    const toggleMode = (checked) => {
        if (checked) {
            document.documentElement.setAttribute('data-theme', 'dark');
            dispatch(toggleDarkMode(true));
        }
        else {
            document.documentElement.setAttribute('data-theme', 'light');
            dispatch(toggleDarkMode(false));
        }
    };

    return (
        <div className="DisplaySettings SettingsGroup">
            <Panel>
                <h2>Display Settings</h2>

                <div className="Setting">
                    <h4>Dark Mode</h4>
                    <Toggle handler={toggleMode} checked={darkMode} />
                </div>
            </Panel>
        </div>
    );
};

export default DisplaySettings;