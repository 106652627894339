import React from 'react';
import './SocialLinks.scss';
import InlineList from '../../components/_styled/InlineList';
import { FaInstagram, FaTwitter } from 'react-icons/fa';
import socialMedia from '../../constants/social';

const SocialLinks = () => {
    const { instagram, twitter } = socialMedia;

    return (
        <div className="SocialLinks">
            <InlineList>
                { instagram && <li className="instagram"><a href={instagram}><FaInstagram /></a></li>}
                { twitter && <li className="twitter"><a href={twitter}><FaTwitter /></a></li>}
            </InlineList>
        </div>
    );
};

export default SocialLinks;
