import React, { useEffect, useState } from 'react';
import './BestEffortsData.css';
import Panel from '../Panel/Panel';
import Table from '../Table/Table';
import { formatTime } from '../../utils/conversion';

const BestEffortsData = ({ data }) => {
    const [bestEfforts, setBestEfforts] = useState([]);
    const columns = [
        { key: 'name', value: 'Name' },
        { key: 'time', value: 'Time' },
    ];

    useEffect(() => {
        const filteredEfforts = filterKeys(data);
        setBestEfforts(filteredEfforts);
    }, [data]);

    const filterKeys = (bestEfforts = []) => {
        const newArr = [];

        bestEfforts.forEach(obj => {
            const { name, elapsed_time } = obj;
            const newObj = {
                name: name,
                time: formatTime(elapsed_time)
            };
            newArr.push(newObj);
        });
        return newArr;
    }
    if (bestEfforts.length > 0) {
        
    }
    return (
        <div className="BestEffortsData dashBlock">
            <Panel title="Best Efforts">
                <div className="BestEffortsData__table">
                    { bestEfforts.length > 0 && <Table columns={columns} data={bestEfforts} />}
                    { !bestEfforts.length && <p>No Best Efforts data available for this activity.</p>}
                </div>
            </Panel>
        </div>
    );
};

export default BestEffortsData;
