import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const TwoColumnRow = ({ left, right }) => {
    return (
        <Row>
            <Col xs={12} lg={7}>
                { left }
            </Col>
            <Col xs={12} lg={5}>
                { right }
            </Col>
        </Row>
    );
};

export default TwoColumnRow;