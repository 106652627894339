import React from 'react';
import 'react-simple-flex-grid/lib/main.css';
import { Container } from '../../components/_styled/Grid';
import './DashboardLayout.css';

const DashboardLayout = ({ children }) => {
    return (
        <div className="Dashboard">
            <Container>
                { children }
            </Container>
        </div>
    );
};

export default DashboardLayout;