import React, { useMemo } from 'react';
import './SortableTableHead.css';
import SortIcon from '../SortIcon/SortIcon';

const TableHead = ({ columns, direction, sortKey, sortClickHandler }) => {
    const cols = useMemo(() => {
        return (
            columns.map(col => (
                <th
                    className={col.key === sortKey ? 'sorted' : null}
                    key={col.key}
                    onClick={() => sortClickHandler(col.key)}
                    aria-sort={col.key === sortKey ? direction : null}
                >
                    {col.value}
                    <SortIcon direction={direction} colKey={col.key} sortKey={sortKey} />
                </th>
            ))
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, sortKey, direction]);

    return (
        <thead>
            <tr>
                { cols }
            </tr>
        </thead>
    );
};

export default TableHead;