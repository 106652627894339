import styled from 'styled-components';

const InlineList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
`;

export default InlineList;
