import { createSlice } from '@reduxjs/toolkit';

export const activitiesTableInitialState = {
    searchFilter: '',
    sortBy: 'date',
    sortOrder: 'descending', // 'ascending' | 'descending'
    rowsPerPage: 10,
    currentPage: 1
};

// @todo - write a thunk for throttling filter async so that we aren't saving to state with every single keystroke

const activitiesTableSlice = createSlice({
    name: 'activitiesTable',
    initialState: activitiesTableInitialState,
    reducers: {
        setSearchFilter: (state, action) => { state.searchFilter = action.payload },
        setSortBy: (state, action) => { state.sortBy = action.payload },
        setSortOrder: (state, action) => { state.sortOrder = action.payload },
        setRowsPerPage: (state, action) => { state.rowsPerPage = action.payload },
        setCurrentPage: (state, action) => { state.currentPage = action.payload },
        setTableState: (state, action) => {
            state.searchFilter = action.payload.searchFilter;
            state.sortBy = action.payload.sortBy;
            state.sortOrder = action.payload.sortOrder;
            state.rowsPerPage = action.payload.rowsPerPage;
            state.currentPage = action.payload.currentPage;
        }
    }
});

export default activitiesTableSlice.reducer;
export const activitiesTableActions = activitiesTableSlice.actions;
