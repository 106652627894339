import React from 'react';
import './SyncButton.scss';
import SyncingSpinner from '../SyncingSpinner/SyncingSpinner';

const SyncButton = ({ syncing = false, children, ...props }) => {
    const content = () => {
        if (syncing) return <SyncingSpinner />;
        return children;
    };

    const buttonContent = content();

    return (
        <button {...props} disabled={syncing} >
            { buttonContent }
        </button>
    );
};

export default SyncButton;
