import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sync, deleteAllData } from '../../store/slices/strava';
import './Settings.css';
import DataSettings from '../../components/Settings/DataSettings';
import DisplaySettings from '../../components/Settings/DisplaySettings';
import SettingsLayout from '../../layouts/Settings/SettingsLayout';
import DashboardHeader from '../../layouts/Dashboard/DashboardHeader/DashboardHeader';
import OneColumnRow from '../../layouts/OneColumnRow/OneColumnRow';
import { SyncMessage } from '../../views/Sync/Sync';
import SyncError from '../../views/Error/SyncError';

const Settings = () => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState('idle');
    const [statusHandler, setStatusHandler] = useState(null); // @todo - does handlerStatus make more sense?
    const { syncStatus, deleteStatus } = useSelector(state => state.strava);

    const syncHandler = () => {
        setStatusHandler('syncing');
        dispatch(sync({ fullSync: true }));
    };

    const refreshHandler = () => {
        setStatusHandler('refreshing');
        dispatch(sync({ forceSync: true }));
    };

    const deleteHandler = () => {
        setStatusHandler('deleting');
        dispatch(deleteAllData());
    };

    useEffect(() => {
        if (statusHandler === 'syncing') {
            if (syncStatus === 'syncing') setStatus('syncing');
            if (syncStatus === 'failed') setStatus('syncFailed');
            if (syncStatus === 'success') setStatus('syncSuccess');
        }

        if (statusHandler === 'refreshing') {
            if (syncStatus === 'refreshing') setStatus('refreshing');
            if (syncStatus === 'failed') setStatus('refreshFailed');
            if (syncStatus === 'success') setStatus('refreshSuccess');
        }

        if (statusHandler === 'deleting') {
            if (deleteStatus === 'deleting') setStatus('deleting');
            if (deleteStatus === 'failed') setStatus('deleteFailed');
            if (deleteStatus === 'success') setStatus('deleteSuccess');
        }
    }, [syncStatus, deleteStatus, statusHandler] );

    if (status === 'syncing') return <SyncMessage />;
    if (status === 'syncFailed') return <SyncError />;

    return (
        <SettingsLayout>
            <DashboardHeader left={<h1 className="pageTitle">Settings</h1>} />

            { /** Display Settings **/}
            <OneColumnRow>
                <DisplaySettings />
            </OneColumnRow>

            { /** Data Settings **/}
            <OneColumnRow>
                <DataSettings 
                    status={status} 
                    syncHandler={syncHandler} 
                    refreshHandler={refreshHandler} 
                    deleteHandler={deleteHandler}
                />
            </OneColumnRow>

        </SettingsLayout>
    );
};

export default Settings;