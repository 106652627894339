import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const FullWidthRow = ({ children }) => {
    return (
        <Row>
            <Col xs={12}>
                { children }
            </Col>
        </Row>
    );
};

export default FullWidthRow;