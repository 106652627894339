import React from 'react';
import './Loading.css';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

const Loading = (props) => {
    const message = props.message ? <p>{ props.message }</p> : null;
    
    return (
        <div className="Loading page Dashboard">
            <div className="pageCenter">
                <LoadingSpinner />
                { message }
                { props.children }
            </div>
        </div>
    );
};

export default Loading;
