import React from 'react';
import history from '../../services/history';
import './BackButton.css';
// import { FaArrowCircleLeft as BackIcon } from 'react-icons/fa';
import { MdArrowBack as BackIcon } from 'react-icons/md';

const BackButton = ({ text, route }) => {

    const clickHandler = () => {
        if (route) {
            history.push(route)
        } else {
            // @todo - replace this with router link
            history.goBack();
        }
    };

    return (
        <button className="BackButton" onClick={() => clickHandler()}>
            <BackIcon />
            <span>{text || 'Go Back'}</span>
        </button>
    );
};

export default BackButton;