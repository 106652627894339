import React from 'react';
import './ActivityHeader.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ActivityHeader = ({ left, right, children }) => {
    return (
        <div className="DashboardHeader ActivityHeader">
            { children }
            <Row>
                <Col xs={12} lg={3}>
                    {left}
                </Col>
                <Col xs={12} lg={9}>
                    {right}
                </Col>
            </Row>
            
        </div>
    );
};

export default ActivityHeader;