import React from 'react';
import { useSelector } from 'react-redux';
import './ActivityStats.css';
import Panel from '../../Panel/Panel';
import Stat from '../../Stat/Stat';
import { formatLongDistance, formatTime, convertAndFormatPace } from '../../../utils/conversion';

const ActivityStats = (props) => {
    const { unit } = useSelector(state => state.strava);

    let {
        distance,
        elapsed_time: time,
        average_speed: pace,
        total_elevation_gain: elevation,
        calories,
        kudos_count: kudos
    } = props;

    distance = formatLongDistance(distance, unit);
    time = formatTime(time);
    pace = convertAndFormatPace(pace, unit);

    return (  
        <div className="ActivityStats dashBlock">
            <Panel>
                <Stat title="Distance" value={ distance } />
                <Stat title="Moving Time" value={ time } />
                <Stat title="Pace" value={ pace } />
                <Stat title="Elevation" value={ elevation } />
                <Stat title="Calories" value={ calories } />
                <Stat title="Kudos" value={ kudos } />
            </Panel>
        </div>
    );
};

export default ActivityStats;