import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import history from '../../services/history';
import { STATS_URL } from '../../constants/api';
import { deauthorize } from './strava';
import { cl } from '../../utils/helper';

export const statsInitialState = {
    status: 'idle', // 'idle' | 'loading' | 'success' | 'fail'
    lastUpdated: null, // timestamp (int) | null
    data: null, // Object | null
    error: null // string | null
}

export const fetchStats = createAsyncThunk('stats/fetchStats',
    async (params = {}, { dispatch }) => {
        try {
            const response = await axios.get(STATS_URL);
            // dispatch(authorize()); // @todo - don't do that!
            return response.data;
            // @todo - could check for response.status (200) or response.statusText ('OK') here
            // if (response.status == 200) {

            // }
        } catch (error) {
            cl(error);
            if (error.response) {
                cl(error.response);
                // @todo - do something with these to handle error in UI
                // @todo - if unauthorized (401), deauthorize app and log out
                // @todo - should we move this into the sync action instead?
                if (error.response.status === 401) {
                    cl('not authorized');
                    dispatch(deauthorize());
                    history.push('/connect') // @todo - logout screen
                }
            }
            return Promise.reject(error);
        }
    });

const statsSlice = createSlice({
    name: 'stats',
    initialState: statsInitialState,
    reducers: {},
    extraReducers: {
        [fetchStats.pending]: state => {
            state.status = 'loading';
            state.error = null;
        },
        [fetchStats.fulfilled]: (state, action) => {
            state.status = 'success';
            state.data = action.payload;
            state.lastUpdated = new Date().getTime();
            state.error = null;
        },
        [fetchStats.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        }
    }
});


export default statsSlice.reducer;