import React from 'react';
import './TableHead.css';

const TableHead = ({ columns }) => {
    return (
        <thead>
            <tr>
                { columns.map(col => (
                    <th key={col.key}>{ col.value }</th>
                )) }
            </tr>
        </thead>
    );
};

export default TableHead;