import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 1.5rem;
`;

export const Row = styled.div`
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
`;

export const Col= styled.div`
    flex: ${(props) => props.size};
    margin: 15px;
`;
