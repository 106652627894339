import React from 'react';
// import { Link } from 'react-router-dom';
import history from '../../services/history';
import './NotFound.css';
import CenterLayout from '../../layouts/CenterLayout/CenterLayout';

const NotFound = () => {
    const redirect = () => {
        history.push('/');
    };
    return (
        <CenterLayout>
            <h1>Page Not Found.</h1>
            <p className="lead">Looks like that page doesn't exist.</p>
            {/* <p><Link to="/dashboard" className="btn btn--primary">Go Back</Link></p> */}
            <p><button className="btn btn--primary" onClick={() => redirect()}>Go Back</button></p>
        </CenterLayout>
    );
};

export default NotFound;