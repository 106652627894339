import React from 'react';
import { useDispatch } from 'react-redux';
import history from '../../services/history';
import { sync } from '../../store/slices/strava';
import Error from './Error';
import './Error.css';

const SyncError = ({ errorMessage, errorTitle, ...props }) => {
    const title = errorTitle || 'Error';
    const message = errorMessage || 'Sync with Strava failed. Please try again later.';

    const dispatch = useDispatch();

    const tryAgainHandler = () => {
        // @todo - unsync, delete any data in store
        dispatch(sync({ fullSync: true }));
    };

    const backHandler = () => {
        // @todo - unsync, delete any data in store
        history.push('/');
    };

    return (
        <Error errorMessage={message} errorTitle={title} {...props} >
            <p className="inlineButtons">
                <button className="btn btn--success" onClick={() => tryAgainHandler()}>Try Again</button>
                <button className="btn btn--primary" onClick={() => backHandler()}>Back</button>
            </p>
        </Error>
    );
};

export default SyncError;
