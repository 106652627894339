import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './Navigation.scss'; 
import history from '../../../services/history';
import { logout } from '../../../store/slices/strava';
import ProfileImage from '../../ProfileImage/ProfileImage';
import { DASHBOARD_URL, SETTINGS_URL } from '../../../constants/routes';
import { MdSettings } from 'react-icons/md';
import { HiOutlineLogout } from 'react-icons/hi';
import { AiFillDashboard } from 'react-icons/ai';

const SubMenu = () => {
    const dispatch = useDispatch();

    const logoutHandler = () => {
        dispatch(logout());
        history.push('/connect');
    };

    return (
        <ul className="NavMenu__subMenu">
            <li className="NavMenu__subMenuItem ">
                <NavLink to={DASHBOARD_URL} activeClassName="active" exact>
                    <AiFillDashboard />
                    <span>Dashboard</span>
                </NavLink>
            </li>
            <li className="NavMenu__subMenuItem ">
                <NavLink to={SETTINGS_URL} activeClassName="active" exact>
                    <MdSettings />
                    <span>Settings</span>
                </NavLink>
            </li>
            <li className="NavMenu__subMenuItem ">
                <a role="button" className="a logout" onClick={() => logoutHandler()} >
                    <HiOutlineLogout />
                    <span>Log Out</span>
                </a>
            </li>
        </ul>
    );
};

const Navigation = () => {
    return (
        <nav className="Navigation">
            <ul className="NavMenu">
                <li className="NavMenu__menuItem">
                    <NavLink to="#" activeClassName="active" exact><ProfileImage /></NavLink>
                    <SubMenu />
                </li>
            </ul>
        </nav>
    );    
};

export default Navigation;