import { createSlice } from '@reduxjs/toolkit';
import { version as currentVersion } from '../../../package.json';
import { deleteState } from '../../utils/store';
import semverLt from 'semver/functions/lt';

const appInitialState = {
    version: currentVersion,
    error: null,
    maintenance: process.env.REACT_APP_MAINTENANCE_MODE === 'true' ? true : false,
    debug: process.env.DEBUG_MODE === 'true' ? true : false
};

// todo - thunk to check if version number stored in localStorage is below the current version
// (in the event of any breaking changes). If so, invalidate (delete) the local data (preferably before
// hydration/preloadedState in configureStore) so that fresh state can be saved instead
// Is redux the best place for this?
export const invalidateLocalStorage = () => (dispatch, getState) => {
    const { version: storedVersion } = getState().app;
    // check if the rehydrated state's version number from localStorage is smaller than current version
    // if it is, delete localStorage to prevent breaking changes
    // @todo - need a way to define safe versions rather than automatically invalidating the whole thing
    // (which would be wasteful if there are no breaking changes to state structure between versions)
    if ( semverLt(storedVersion, currentVersion) ) deleteState();
    return Promise.resolve();
}

const appSlice = createSlice({
    name: 'app',
    initialState: appInitialState,
    reducers: {}
});

export default appSlice.reducer;
