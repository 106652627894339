import React from 'react';
import { useSelector } from 'react-redux';
import './UserStats.css';
import Panel from '../Panel/Panel';
import Stat from '../Stat/Stat';
import { formatLongDistance, formatTime } from '../../utils/conversion';

const UserStats = ({ stats }) => {
    const { unit } = useSelector(state => state.strava);
    const runs = stats.all_run_totals.count;
    const distance = formatLongDistance(stats.all_run_totals.distance, unit);
    const time = formatTime(stats.all_run_totals.elapsed_time);

    return (
        <div className="UserStats dashBlock">
            <Panel>
                <Stat title="Runs" value={ runs } />
                <Stat title="Distance" value={ distance } />
                <Stat title="Total Running Time" value={ time } />
            </Panel>
        </div>
    );
};

export default UserStats;
