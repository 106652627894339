import React from 'react';
import './DashboardHeader.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const DashboardHeader = ({ left, right }) => {
    return (
        <div className="DashboardHeader">
            <Row>
                <Col xs={12} lg={4}>
                    { left }
                </Col>
                <Col xs={12} lg={8}>
                    {right}
                </Col>
            </Row>            
        </div>
    );
};

export default DashboardHeader;