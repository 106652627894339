import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';
import rootReducer from './rootReducer';
import { loadState, saveState } from '../utils/store';
import monitorReducerEnhancer from './enhancers/monitorReducer';
import { athleteInitialState } from './slices/athlete';
import { activitiesInitialState } from './slices/activities';
import { stravaInitialState } from './slices/strava';
import { statsInitialState } from './slices/stats';

const persistedState = loadState();
const middleware = [thunk, ...getDefaultMiddleware()];
const enhancers = [];

if ( process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEBUG_MODE === 'true' ) {
    const { logger } = require('redux-logger');
    middleware.push(logger);
    enhancers.push(monitorReducerEnhancer);
}

const store = configureStore({
    reducer: rootReducer,
    preloadedState: persistedState,
    middleware: middleware,
    enhancers: enhancers
});

store.subscribe(throttle(() => {
    const { athlete, stats, activities, strava, settings } = store.getState();
    saveState({
        athlete: {
            ...athleteInitialState,
            data: athlete.data,
            lastUpdated: athlete.lastUpdated
        },
        activities: {
            ...activitiesInitialState,
            data: activities.data,
            lastUpdated: activities.lastUpdated
        },
        stats: {
            ...statsInitialState,
            data: stats.data,
            lastUpdated: stats.lastUpdated
        },
        strava: {
            ...stravaInitialState,
            authorized: strava.authorized,
            lastSync: strava.lastSync,
            scope: strava.scope,
            unit: strava.unit
        },
        settings: settings
    });
}, 1000));

export default store;
