import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './Header.scss';
import logo from '../../assets/images/logo/stranalytics-logo-color.svg';
import Navigation from './Navigation/Navigation';
import { Container } from '../_styled/Grid';

const Header = () => {
    const { authorized: auth } = useSelector(state => state.strava);

    return (
        <header className="Header">
            <Container>
                <div className="Header__wrapper">
                    <div className="Header__brand">
                        <Link to="/">
                            <img src={logo} alt="Stranalytics" />
                        </Link>
                    </div>
                    {auth && <Navigation />}
                </div>
            </Container>
        </header> 
    );
};

export default Header;