import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './App.scss';
import Routes from './Routes';
import Header from './components/Header/Header';
import SiteFooter from './components/SiteFooter/SiteFooter';

function App() {
  const { darkMode } = useSelector(state => state.settings);

  const setDarkMode = () => {
    document.documentElement.setAttribute('data-theme', 'dark');
  };

  useEffect(() => {
    if (darkMode) setDarkMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
        <Header />
          <Routes />
        <SiteFooter />
    </div>
  );
}

export default App;
