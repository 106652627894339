import React, { useMemo } from 'react';
import './Profile.scss';
import ProfileImage from '../ProfileImage/ProfileImage';
import Panel from '../Panel/Panel';
import { FaMapMarkerAlt } from 'react-icons/fa';

const Profile = ({ user }) => {
    const location = useMemo(() => {
        const city = (user.city && user.country) ? `${user.city}, `
            : !user.city ? ''
            : user.city;

        const country = user.country? user.country : '';

        return `${city}${country}`;
    }, [user]);

    return (
        <div className="Profile">
            <Panel>
                <ProfileImage imgSrc={ user.profile }/>
                <h1 className="Profile__heading">{ user.firstname } { user.lastname }</h1>
                { location && 
                    <div className="Profile__location">
                        <FaMapMarkerAlt /><h4>{ location }</h4>
                    </div>
                }
            </Panel>
        </div>
    );
};

export default Profile;