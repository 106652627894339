import React from 'react';
import './SyncingSpinner.css';
import { FaSyncAlt } from 'react-icons/fa';

const SyncingSpinner = () => {
    return (
        <div className="SyncingSpinner">
            <FaSyncAlt />
            {/* <div className="lds-dual-ring"></div> */}
        </div>
    );
};

export default SyncingSpinner;
