import React from 'react';
import './SiteFooter.scss';
import Footer from '../_styled/Footer';
import { Container } from '../_styled/Grid';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import stravaAPILogo from '../../assets/images/strava/api_logo_pwrdBy_strava_horiz_gray.svg';
import logo from '../../assets/images/logo/stranalytics-logo-color.svg';
import SocialLinks from '../SocialLinks/SocialLinks';
import SubFooterMenu from './SubFooterMenu/SubFooterMenu';
import Copyright from './Copyright/Copyright';

const SiteFooter = () => {
    return (
        <Footer className="SiteFooter">
            <Container>
                <Row>
                    <Col xs={12} md={4} className="col-start">
                        <img className="stranalyticsLogo" src={logo} alt="Stranalytics" />
                    </Col>
                    <Col xs={12} md={4} className="col-center">
                        <img className="stravaAPILogo" src={stravaAPILogo} alt="Powered by Strava" />
                    </Col>
                    <Col xs={12} md={4} className="col-end">
                        <SocialLinks />
                    </Col>
                </Row>
                <Row className="no-padding">
                    <Col xs={12}>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="col-center">
                        <SubFooterMenu />
                    </Col>
                    <Col xs={12} className="col-center">
                        <Copyright />
                    </Col>
                </Row>
            </Container>
        </Footer>
    );
};

export default SiteFooter;
