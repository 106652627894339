import React from 'react';
import './StravaConnectButton.css';
import connectWithStrava from '../../assets/images/strava/btn_strava_connectwith_orange.svg';
import { AUTH_URL } from '../../constants/api';

const StravaConnectButton = () => {
    return (
        <a className="StravaConnectButton" href={AUTH_URL}>
            <span className="screenReaderText">Connect with Strava</span>
            <img src={connectWithStrava} alt="Connect with Strava"/>
        </a>
    );
};

export default StravaConnectButton;